/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Sell from 'containers/Fiscal/Sell';
import Report from 'containers/Fiscal/Report';
import ZReportsList from 'containers/Fiscal/ZReports/ZReportsList';
import BuyAndRefund from 'containers/Fiscal/BuyAndRefund';
import RefuntOfOperation from 'containers/Fiscal/RefuntOfOperation';
import IncomeAndOutcome from 'containers/Fiscal/IncomeAndOutcome';
import FiscalLicenseAlert from 'containers/Fiscal/FiscalLicenseAlert';
import * as fiscalActions from 'actions/fiscal';
import { showError } from 'actions/alert';
import * as pultActions from 'actions/pult';
import { getEncashmentList } from 'actions/encashment';
import { FISCAL_SCREENS, X_REPORT_TYPE, Z_REPORT_TYPE } from 'constants/fiscal';
import Button from 'components/UI/Button/button';
import { getTerminalInfo } from 'model/Terminal';
import { getFromLocalStorage, getObjectElement, setToLocalStorage, } from 'helpers/main';
import {
  isFiscalTokenAlive,
  hasUid,
  clearSections,
  hasSectionInfo,
  getFiscalData,
  getShiftStatus,
  hasFiscalTempUid,
  saveFiscalTempUid,
  hasUnits,
  checkFiscalAndApSendedPays
} from 'helpers/fiscal';

import './fiscal.scss';
import ServicePayment from './ServicePayment';
import FiscalNotSendedPays from './FiscalNotSendedPays';

const SHIFT_CLASSES = {
  '-': '',
  Открыта: 'opened',
  Закрыта: 'closed',
  'Смена открыта другим кассиром': 'pending',
};

const FISCAL_MODAL_DATA = {
  [FISCAL_SCREENS.BUY]: <BuyAndRefund modalTitle="Покупка" operation="purchases" />,
  [FISCAL_SCREENS.SELL]: <Sell />,
  [FISCAL_SCREENS.INCOME]: <IncomeAndOutcome modalTitle="Служебный приход" operation="incomes" />,
  [FISCAL_SCREENS.OUTCOME]: <IncomeAndOutcome modalTitle="Служебный расход" operation="expenses" />,
  [FISCAL_SCREENS.REFUND_OF_SALE]: <BuyAndRefund modalTitle="Возврат продажи" operation="refunds" />,
  [FISCAL_SCREENS.X_REPORT]: <Report type={X_REPORT_TYPE} />,
  [FISCAL_SCREENS.Z_REPORTS_HISTORY]: <ZReportsList />,
  [FISCAL_SCREENS.CLOSE_SHIFT]: <Report type={Z_REPORT_TYPE} />,
  [FISCAL_SCREENS.REFUND]: <RefuntOfOperation />,
  [FISCAL_SCREENS.SERVICE_PAYMENT]: <ServicePayment/>
};

const Fiscal = (props) => {
  const {
    getUid,
    isFiscal,
    getKkmInfo,
    getArticles,
    getSections,
    getUnits,
    getShiftInfo,
    getXReportData,
    sendPaysToFiscal,
    getFiscalTariff,
    getFiscalBalances,
    getFiscalLicenseList,
    preparePayForFiscalService,
    tariff,
    articles,
    balances,
    screenType,
    invoiceData,
    licenseList,
    isAuthInFiscal,
    setShowPinCodeModal,
    setTypeOperation,
    wasArticlesRequested,
    showLicenseExpiredModal,
    kkmInfo: { Id: kkmId, Lock, IdShift, Znm },
    fiscalXReportsSnList,
    snListForTodayPayments,
    xReport,
  } = props;
  const shiftStatus = getShiftStatus(Lock, IdShift);
  const [modalScreen, setModalScreen] = useState(<div />);
  const [openFiscalModalWithSnError, setOpenFiscalModalWithSnError] = useState(false);
  const [snListNotSendedToFiscal, setSnListNotSendedToFiscal] = useState([]);
  const [isAgreedShowFiscalAlert, setIsAgreedShowFiscalAlert] = useState(true);
  const [isTheSystemTerminal, setIsTheSystemTerminal] = useState(false);

  const terminalInfo = getTerminalInfo();
  const classNameForShiftStatus = getObjectElement(SHIFT_CLASSES, shiftStatus);

  useEffect(() => {
    const { sectionIdForPayment, sectionIdForCommission, sections } = getFiscalData();

    if (
      (!sectionIdForPayment || !sectionIdForCommission) &&
      Array.isArray(sections) &&
      sections.length
    ) {
      clearSections();
      props.showError('Проблема с секциями. Обратитесь в Службу Поддержки для создания секции');
    }
  }, [isAuthInFiscal]);

  useEffect(() => {
    checkShiftInfo();
  }, [isAuthInFiscal]);

  useEffect(() => {
    if (isAuthInFiscal && shiftStatus === 'Открыта') {
      getXReportData({});
    }
  }, [isAuthInFiscal, snListForTodayPayments, shiftStatus]);

  useEffect(() => {
    if (getFromLocalStorage('getUidTryCount')) {
      setToLocalStorage('getUidTryCount', 0);
    }
  }, []);

  useEffect(() => {
    checkTerminalInfo();
  }, [terminalInfo]);

  useEffect(() => {
    checkShowAlertAgree();
  }, []);

  useEffect(() => {
    // если был переход с фискала и мы получили uid, сохраняем его
    checkHasFiscalTempUid();
  }, []);

  useEffect(() => {
    if (screenType) {
      setModalScreen(FISCAL_MODAL_DATA[Number(screenType)]);
    } else {
      setModalScreen(<div />);
    }
  }, [screenType]);

  useEffect(() => {
    if (isAuthInFiscal && isFiscalTokenAlive()) {
      checkKkm();
      checkSections();
      checkUnits();
    }
  }, [isAuthInFiscal, kkmId, Lock]);

  useEffect(() => {
    checkFiscalTariff();
  }, [isAuthInFiscal, tariff]);

  useEffect(() => {
    if (isAuthInFiscal && isFiscalTokenAlive() && !balances.wasRequested) {
      getFiscalBalances();
    }
  }, [isAuthInFiscal, balances.wasRequested]);

  useEffect(() => {
    checkUid();
  }, [getUid, isAuthInFiscal, isFiscal, Lock]);

  useEffect(() => {
    checkArticles();
  }, [isAuthInFiscal, articles, wasArticlesRequested]);

  useEffect(() => {
    if (isAuthInFiscal && !licenseList.length && !isTheSystemTerminal) {
      getFiscalLicenseList();
    }
  }, [isAuthInFiscal, licenseList, isTheSystemTerminal]);

  useEffect(() => {
    const { id, amount } = invoiceData;

    if (amount && id) {
      preparePayForFiscalService(id);
    }
  }, [invoiceData]);

  useEffect(() => {
    const notInFiscalSnList = checkFiscalAndApSendedPays({
      snListForTodayPayments,
      xReport,
      shiftStatus,
      fiscalXReportsSnList });

    setSnListNotSendedToFiscal(notInFiscalSnList);
  }, [snListForTodayPayments, fiscalXReportsSnList, shiftStatus]);

  const checkKkm = useCallback(() => {
    const hasKkmInfo = typeof kkmId !== 'undefined';

    if (!hasKkmInfo) {
      // будем запрашивать после авторизации + перед каждым платежом на платёж
      getKkmInfo();
    }
  }, [kkmId]);

  const checkSections = useCallback(() => {
    if (!hasSectionInfo() && Lock) {
      getSections();
    }
  }, [Lock]);

  const checkUnits = useCallback(() => {
    if (!hasUnits() && Lock) {
      getUnits();
    }
  }, [Lock]);

  const checkHasFiscalTempUid = () => {
    if (hasFiscalTempUid()) {
      saveFiscalTempUid();
    }
  };

  const checkUid = useCallback(() => {
    if (isAuthInFiscal && isFiscalTokenAlive() && !hasUid() && Lock) {
      getUid();
    }
  }, [isAuthInFiscal, Lock]);

  const checkArticles = useCallback(() => {
    if (isAuthInFiscal && isFiscalTokenAlive() && !articles.length && !wasArticlesRequested) {
      getArticles();
    }
  }, [isAuthInFiscal, articles, wasArticlesRequested]);

  const checkShiftInfo = useCallback(() => {
    if (isAuthInFiscal && isFiscalTokenAlive()) {
      getShiftInfo();
    }
  }, [isAuthInFiscal]);

  const checkTerminalInfo = useCallback(() => {
    if (terminalInfo) {
      const { isSystem } = terminalInfo;

      setIsTheSystemTerminal(isSystem);
    }
  }, [terminalInfo]);

  const checkShowAlertAgree = useCallback(() => {
    const { showAlertIsAgreed } = getFiscalData();

    if (typeof showAlertIsAgreed !== 'undefined' && !showAlertIsAgreed) {
      setIsAgreedShowFiscalAlert();
    }
  }, []);

  const checkFiscalTariff = useCallback(() => {
    if (isAuthInFiscal && isFiscalTokenAlive() && tariff === null) {
      getFiscalTariff();
    }
  }, [isAuthInFiscal, tariff]);

  const checkClassNameForShiftStatus = useCallback(() => {
    if (classNameForShiftStatus === 'pending') return 'Смена открыта другим кассиром';

    return null;
  }, [classNameForShiftStatus]);

  const closeFiscalModalWithSnError = () => {
    setOpenFiscalModalWithSnError(false);
  };

  const backFiscalModalWithSnError = () => {
    setOpenFiscalModalWithSnError(false);
    openAndCloseShiftHandler(FISCAL_SCREENS.CLOSE_SHIFT, true);
  };

  const sendPaysToFiscalHandler = () => {
    sendPaysToFiscal(snListNotSendedToFiscal);
    setOpenFiscalModalWithSnError(false);
  };

  const openAndCloseShiftHandler = (typeOperation, continueWithoutSendPays = false) => {
    if (typeOperation === FISCAL_SCREENS.CLOSE_SHIFT &&
      snListNotSendedToFiscal.length > 0 && !continueWithoutSendPays) {
      setOpenFiscalModalWithSnError(true);
    } else {
      setShowPinCodeModal(true);
      setTypeOperation(typeOperation);
    }
  };

  const getClassNameForShiftStatus = () => {
    if (classNameForShiftStatus === 'opened') {
      return 'fiscal-block-success';
    }

    if (classNameForShiftStatus === 'closed') {
      return 'fiscal-block-fail';
    }

    return 'fiscal-block-pending';
  };

  const { userData, idKkm } = getFiscalData();

  return (
    <>
      {modalScreen}
      {openFiscalModalWithSnError && (
        <FiscalNotSendedPays
          sn={snListNotSendedToFiscal}
          onClose={closeFiscalModalWithSnError}
          onOk={sendPaysToFiscalHandler}
          onBack={backFiscalModalWithSnError}
        />
      )}

      {(showLicenseExpiredModal && isAgreedShowFiscalAlert) && (
        <FiscalLicenseAlert />
      )}
      <div className={`fiscal-block  ${getClassNameForShiftStatus()}`}>
        <span><b>Кассир:</b> {userData.Name}. <b>Id кассы:</b> {idKkm || '-'}. <b>ЗНМ:</b> {Znm || '-'}. <b>Сумма наличных в кассе:</b> {Number(balances.cash).toLocaleString() || '0'} тг. <b>Сумма безналичных в кассе:</b> {balances.card || '0'} тг</span>
        <span className="status">
          {classNameForShiftStatus === 'opened' && (
            <>
              <span className="fiscal-status-success-title">{`Смена ${shiftStatus.toLowerCase()}`}</span>
              <Button
                className="fiscal-block-button"
                onClick={() => openAndCloseShiftHandler(FISCAL_SCREENS.CLOSE_SHIFT)}
              >
                Закрыть смену
              </Button>
            </>
          )}

          {classNameForShiftStatus === 'closed' && (
            <>
              <span className="fiscal-status-fail-title">{`Смена ${shiftStatus.toLowerCase()}`}</span>
              <Button
                className="fiscal-block-button"
                onClick={() => openAndCloseShiftHandler(FISCAL_SCREENS.OPEN_SHIFT)}
              >
                Открыть смену
              </Button>
            </>
          )}
          {checkClassNameForShiftStatus()}
        </span>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    hasUid: state.fiscal.hasUid,
    articles: state.fiscal.articles,
    isFiscal: state.fiscal.isFiscal,
    isAuthInFiscal: state.fiscal.isAuthInFiscal,
    kkmInfo: state.fiscal.kkmInfo,
    tariff: state.fiscal.tariff,
    balances: state.fiscal.balances,
    licenseList: state.fiscal.licenseList,
    invoiceData: state.fiscal.invoiceData,
    screenType: state.fiscal.modalData.screenType,
    wasArticlesRequested: state.fiscal.wasArticlesRequested,
    showLicenseExpiredModal: state.fiscal.showLicenseExpiredModal,
    showPinCodeModal: state.fiscal.showPinCodeModal,
    fiscalXReportsSnList: state.fiscal.fiscalXReportsSnList,
    snListForTodayPayments: state.payment.snListForTodayPayments,
    xReport: state.fiscal.xReport,
    encashmentList: state.encashment.encashmentList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...fiscalActions,
    ...pultActions,
    showError,
    getEncashmentList
  }, dispatch);
}

Fiscal.propTypes = {
  isFiscal: PropTypes.bool.isRequired,
  isAuthInFiscal: PropTypes.bool.isRequired,
  wasArticlesRequested: PropTypes.bool.isRequired,
  showLicenseExpiredModal: PropTypes.bool.isRequired,
  getUid: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  getKkmInfo: PropTypes.func.isRequired,
  getSections: PropTypes.func.isRequired,
  getArticles: PropTypes.func.isRequired,
  getShiftInfo: PropTypes.func.isRequired,
  getXReportData: PropTypes.func.isRequired,
  getFiscalTariff: PropTypes.func.isRequired,
  getFiscalBalances: PropTypes.func.isRequired,
  getFiscalLicenseList: PropTypes.func.isRequired,
  preparePayForFiscalService: PropTypes.func.isRequired,
  kkmInfo: PropTypes.object.isRequired,
  balances: PropTypes.object.isRequired,
  invoiceData: PropTypes.object.isRequired,
  licenseList: PropTypes.array.isRequired,
  articles: PropTypes.array.isRequired,
  tariff: PropTypes.number,
  screenType: PropTypes.number.isRequired,
  setShowPinCodeModal: PropTypes.func.isRequired,
  setTypeOperation: PropTypes.func.isRequired,
  getUnits: PropTypes.func.isRequired,
  fiscalXReportsSnList: PropTypes.array.isRequired,
  snListForTodayPayments: PropTypes.array.isRequired,
  xReport: PropTypes.object.isRequired,
  sendPaysToFiscal: PropTypes.func.isRequired,

};

Fiscal.defaultProps = {
  tariff: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fiscal);
