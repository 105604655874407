import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { MAX_FAVOURITES_COUNT } from 'constants/all';
import { BLOCKED_SERVICES, KASPI_LOGO, KASPI_SERVICE_NAME } from 'constants/services';
import {
  CASH_OUT_PAY_TYPE,
  MYPOST_PAY_TYPE_PAYMENT,
  MYPOST_PAY_TYPE_DELIVERY,
  PAY_TYPES
} from 'constants/payTypes';

import * as pay from 'actions/pay';
import * as alert from 'actions/alert';
import Confirm from 'containers/Confirm';
import { makePay } from 'actions/api/makePay';
import { getServiceLogo, iinIsValidOrEmptyFunc } from 'helpers/pay';
import Alert from 'components/UI/Alert/alert';
import { setFavourites } from 'actions/services';
import { getBalance } from 'actions/api/getBalance';
import { onlineCheck } from 'actions/api/onlineCheck';
import { getCashoutReport } from 'actions/api/reports';
import { sendInfoLogToGateway } from 'actions/api/logs';
import Bookmark from 'components/UI/Bookmark/bookmark';
import logoKassa from 'public/images/kassa24/kassa24.svg';
import CashOut from 'components/PayTypes/CashOut/CashOut';
import { searchPayments } from 'actions/api/paymentHistory';
import CommissionProfile from 'containers/CommissionProfile';
import MyPostPayment from 'components/PayTypes/MyPost/Payment';
import MyPostDelivery from 'components/PayTypes/MyPost/Delivery';
import PaymentCounter from 'components/PaymentCounter/paymentcounter';
import { t, checkUnicodeSymbols, generateTransactionNumber } from 'helpers/main';
import { getFavourites, setToFavourites, removeFromFavouritesOnId } from 'model/Favourites';

import EnterAmount from './EnterAmount';

class Pay extends Component {
  componentDidUpdate(prevProps) {
    const prevIdService = prevProps.kassa.currentService.idService;
    const prevSN = prevProps.payment.SN;
    const {
      kassa: { currentService: { idService } },
      payment: { SN }
    } = this.props;

    if ((prevIdService !== idService && !SN) || (prevSN !== SN && !SN)) {
      const sn = generateTransactionNumber();

      this.props.setTransactionNumber(sn);
    }
  }

  getDefaultImage = (e) => {
    e.target.src = logoKassa;
  }

  removeServiceFromFavourite = (idService) => {
    if (typeof idService !== 'undefined') {
      const favourites = removeFromFavouritesOnId(idService);

      this.props.setFavourites(favourites);
    }
  }

  addServiceToFavourite = (idService) => {
    if (typeof idService !== 'undefined') {
      const savedFavourites = getFavourites();

      if (savedFavourites.length < MAX_FAVOURITES_COUNT) {
        const favourites = setToFavourites(idService);

        this.props.showSuccess('Сервис успешно добавлен в избранные');
        this.props.setFavourites(favourites);
      } else {
        this.props.sendInfoLogToGateway({
          message: `savedFavourites: ${JSON.stringify(savedFavourites)}, savedFavourites.length: ${savedFavourites.length}, MAX_FAVOURITES_COUNT: ${MAX_FAVOURITES_COUNT}`,
          reqType: 'favorites'
        });
        this.props.showError(`Максимальное количество сервисов в избранном: ${MAX_FAVOURITES_COUNT}`);
      }
    }
  }

  getScreen(screenType, Form) {
    let screen = <div />;

    if (screenType === 0) {
      screen = <Form {...this.props} iinIsValidOrEmpty={this.iinIsValidOrEmpty} />;
    } else if (screenType === 1) {
      screen = <EnterAmount />;
    } else if (screenType === 2) {
      screen = <Confirm />;
    }

    return screen;
  }

  getForm = (typeInterface, idService) => {
    let Form = () => (<div />);

    if (typeof PAY_TYPES[Number(typeInterface)] !== 'undefined' && !BLOCKED_SERVICES.includes(idService)) {
      Form = PAY_TYPES[Number(typeInterface)];
    } else if (
      typeInterface !== CASH_OUT_PAY_TYPE &&
        typeInterface !== MYPOST_PAY_TYPE_PAYMENT &&
        typeInterface !== MYPOST_PAY_TYPE_DELIVERY
    ) {
      Form = () => (
        <div style={{ marginTop: 20 }}>
          <Alert message={t('serviceIsNotAvailable')} banner />
        </div>
      );
    }

    return Form;
  }

  getKaspiServiceInfo = () => {
    const { otherIdServiceForMakePay } = this.props.kassa;
    const kaspiServiceName = otherIdServiceForMakePay ? KASPI_SERVICE_NAME[String(otherIdServiceForMakePay)] : '';
    const kaspiLogo = otherIdServiceForMakePay ?
      KASPI_LOGO[String(otherIdServiceForMakePay)] : '';

    return { kaspiServiceName, kaspiLogo };
  };

  getFavouriteButton = () => {
    const favourites = getFavourites();
    const { name, idService } = this.props.kassa.currentService;

    let favouriteButton = <div />;

    if (typeof name !== 'undefined') {
      if (favourites.length && favourites.includes(idService)) {
        favouriteButton = (
          <Bookmark checked onClick={() => this.removeServiceFromFavourite(idService)} />
        );
      } else {
        favouriteButton = (
          <Bookmark onClick={() => this.addServiceToFavourite(idService)} />
        );
      }
    }

    return favouriteButton;
  };

  iinIsValidOrEmpty = () => {
    const { clientIin } = this.props.payment;

    return iinIsValidOrEmptyFunc(clientIin, this.props.showError);
  };

  render() {
    const { typeInterface, name, logo, idService } = this.props.kassa.currentService;
    const { kaspiServiceName, kaspiLogo } = this.getKaspiServiceInfo();
    const Form = this.getForm(typeInterface, idService);
    const { screenType } = this.props.pay;
    const screen = this.getScreen(screenType, Form);
    const favouriteButton = this.getFavouriteButton();

    return (
      <div className="pay-block-wrapper">
        <div className="pay-block">
          {this.props.cashout.showCashOutModal && (
            <CashOut />
          )}
          {this.props.mypost.showMyPostPaymentModal && (
            <MyPostPayment />
          )}
          {this.props.mypost.showMyPostDeliveryParcelModal && (
            <MyPostDelivery />
          )}
          <div className="pay-header">
            <h2>Реквизиты платежа</h2>
            {favouriteButton}
          </div>
          {(
            typeof name !== 'undefined' &&
            typeInterface !== CASH_OUT_PAY_TYPE &&
            typeInterface !== MYPOST_PAY_TYPE_PAYMENT &&
            typeInterface !== MYPOST_PAY_TYPE_DELIVERY) ? (
              <>
                <CommissionProfile />
                <div className="pay-title">
                  <img src={getServiceLogo(kaspiLogo || logo) || this.getDefaultImage} alt={name} className="pay-logo" />
                  <h3 className="pay-service-name">{checkUnicodeSymbols(kaspiServiceName || name)}</h3>
                </div>
                <div>
                  {screen}
                </div>
              </>
            ) : (
              <>
                {(
                  typeInterface === MYPOST_PAY_TYPE_PAYMENT ||
                  typeInterface === MYPOST_PAY_TYPE_DELIVERY
                ) &&
                  <CommissionProfile />
                }
                <Alert message="Выберите сервис для оплаты" banner />
              </>
            )}
        </div>
        <PaymentCounter />
      </div>
    );
  }
}

Pay.propTypes = {
  pay: PropTypes.object.isRequired,
  kassa: PropTypes.object.isRequired,
  mypost: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
  cashout: PropTypes.object.isRequired,
  showSuccess: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  setFavourites: PropTypes.func.isRequired,
  sendInfoLogToGateway: PropTypes.func.isRequired,
  setTransactionNumber: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...pay,
    ...alert,
    makePay,
    getBalance,
    onlineCheck,
    setFavourites,
    searchPayments,
    getCashoutReport,
    sendInfoLogToGateway,
  },
  dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Pay);
