import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import logoKassa from 'public/images/kassa24/kassa24.svg';
import {
  closeShift,
  setFiscalScreen,
  setShowLicenseModal,
  setShowPinCodeModal,
  setShowConfirmCloseShift,
  setOpeningShiftCallback,
  setNeedToOpenShift,
  setTypeOperation,
} from 'actions/fiscal';
import { goToMainPage } from 'actions/navigate';
import { showError } from 'actions/alert';
import { createInvoiceForKkm } from 'actions/pult';
import { getServicesList } from 'actions/services';
import { getCommissionProfiles } from 'actions/commission';
import { setShowCashOutModal, selectCashOutService } from 'actions/payTypes/cashout';
import { setShowMyPostPaymentModal, selectMyPostService } from 'actions/payTypes/mypost';
import { getAuthData } from 'model/UserDb';
import Encashment from 'containers/Encashment';
import ConfirmModal from 'components/UI/ConfirmModal/confirmModal';
import FiscalLicenses from 'components/Fiscal/FiscalLicenses';
import TerminalIconWhite from 'public/images/terminal_icon_white.svg';
import MyPostIcon from 'public/images/mypost.svg';
import { getShiftStatus, isTheShiftActive } from 'helpers/fiscal';
import { canDoCashOut } from 'helpers/cert';
import Notification from 'components/Notifications/Notifications';
import SearchService from './SearchService';
import StatusBlock from './StatusBlock';
import PinCodeModal from './PinCodeModal';
import MenuAdditionally from './MenuAdditionally';
import MenuOperations from './MenuOperations';
import './header.scss';

class HeaderBlock extends Component {
  componentDidMount() {
    const { servicesByTypes, serviceList, commissionProfiles } = this.props;
    const { guid } = getAuthData();

    if (guid !== '') {
      if (!servicesByTypes.length || !serviceList.length) {
        this.props.getServicesList();
      }

      if (!commissionProfiles.length) {
        this.props.getCommissionProfiles();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { Lock } = this.props.fiscal.kkmInfo;
    const { openingShiftCallback } = this.props;

    if (prevProps.fiscal.kkmInfo.Lock !== Lock && Lock && openingShiftCallback) {
      openingShiftCallback();
      setTimeout(() => {
        this.props.setOpeningShiftCallback(null);
      }, 2000);
    }
  }

  checkShiftStatus = () => {
    const { isFiscal, isAuthInFiscal, kkmInfo: { Lock, IdShift } } = this.props;
    const shiftStatus = getShiftStatus(Lock, IdShift);

    if (!isFiscal) return true;

    if (!isAuthInFiscal) {
      this.props.showError('Не удалось авторизоваться в фискале. Обратитесь в ТП');

      return false;
    }

    if (Lock) {
      if (isTheShiftActive()) return true;

      this.props.showError('Смена просрочена. Закройте текущую смену и откройте новую');

      return false;
    }

    if (!shiftStatus) {
      this.props.showError('Пожалуйста подождите, информация о смене загружается');

      return false;
    }

    if (shiftStatus === 'Закрыта') {
      this.props.showError('Необходимо открыть смену, чтобы продолжить работу с платежами');

      return false;
    }

    return true;
  };

  handleCashoutClick = () => {
    if (!this.props.isFiscal || this.checkShiftStatus()) {
      this.props.selectCashOutService();
      this.props.setShowCashOutModal(true);
    }
  };

  handleMyPostClick = () => {
    if (!this.props.isFiscal || this.checkShiftStatus()) {
      this.props.selectMyPostService();
      this.props.setShowMyPostPaymentModal(true);
    }
  };

  getMayPay = (mayPay) => {
    if (mayPay !== 0) return mayPay;

    return 0;
  }

  getBalance = (balance) => {
    if (balance !== '') return balance;

    return 0;
  }

  render() {
    const {
      fiscal: {
        isFiscal,
        licenseList,
        isAuthInFiscal,
        showLicenseModal,
        kkmInfo: {
          Id: IdKkm,
        }
      },
      showConfirmCloseShift,
      showPinCodeModal,
      needToOpenShift,
      openingShiftCallback,
      typeOperation,
      balances: { balance, mayPay },
    } = this.props;

    return (
      <div className="header">
        <div className="header-wrapper">
          {/* Если тариф бесплатный или не выбранный, отображаем окно с тарифами */}
          {showLicenseModal && (
            <FiscalLicenses
              licenseList={licenseList}
              setShowLicenseModal={this.props.setShowLicenseModal}
              showError={this.props.showError}
              createInvoiceForKkm={this.props.createInvoiceForKkm}
            />
          )}
          {/* Вызывается перед открытием или закрытием смены */}
          <PinCodeModal
            typeOperation={typeOperation}
            showPinCodeModal={showPinCodeModal}
            setShowPinCodeModal={this.props.setShowPinCodeModal}
            setShowConfirmCloseShift={this.props.setShowConfirmCloseShift}
            showOpenShiftSuccesMsg={!openingShiftCallback}
            resetTypeOperation={() => this.props.setTypeOperation(0)}
          />
          {/* Вызывается после пин-кода и перед закрытием смены */}
          <ConfirmModal
            isOpen={showConfirmCloseShift}
            okText="Закрыть смену"
            onOk={() => this.props.closeShift(IdKkm)}
            onClose={() => this.props.setShowConfirmCloseShift(false)}
            hideModal={() => this.props.setShowConfirmCloseShift(false)}
            title="Вы собираетесь закрыть смену!"
            message="Итоги закрытия смены будут отправлены в налоговую. Вы уверены что хотите закрыть смену?"
          />
          {/* Вызывается если смена закрыта и кликают на любую фиск операцию или инкассацию */}
          <ConfirmModal
            isOpen={needToOpenShift}
            okText="Да"
            onOk={() => this.props.setShowPinCodeModal(true)}
            onClose={() => {
              this.props.setNeedToOpenShift(false);
              this.props.setOpeningShiftCallback(null);
            }}
            hideModal={() => this.props.setNeedToOpenShift(false)}
            title="Для выполнения этой операции нужно открыть смену"
            message="Вы хотите открыть смену?"
          />
          <button type="button" tabIndex={-1} className="logo-block buttonWithoutStyles" onClick={() => this.props.goToMainPage()}>
            <img src={logoKassa} alt="kassa24" className="logo-kassa24" />
          </button>

          {(this.props.hasCashOut && canDoCashOut()) ? (
            <div>
              <button
                type="button"
                className="cashout-button"
                onClick={this.handleCashoutClick}
              >
                <img src={TerminalIconWhite} alt=" Выдача средств" /> {' '}
                Выдача наличных
              </button>
            </div>
          ) : <div />}

          {(!canDoCashOut() && this.props.hasMyPost) ? (
            <div>
              <button
                type="button"
                className="mypost-button"
                onClick={this.handleMyPostClick}
              >
                <img src={MyPostIcon} alt=" Выдача посылки" width="100px" />
              </button>
            </div>
          ) : <div />}

          <div className="header-search">
            <SearchService />
          </div>

          {balance < 500000 && mayPay < 500000 ? (
            <div className="header-payment-balance">
              <div className="header-amount-wrapper">
                <span>Доступно</span>
                <span>{this.getMayPay(mayPay)} тг</span>
              </div>
              <div className="header-devider" />
              <div className="header-amount-wrapper">
                <span>Баланс</span>
                <span>{this.getBalance(balance)} тг</span>
              </div>
            </div>
          ) : (
            <div className="header-balance-text">
              <span>Баланс больше 500 000 тг</span>
            </div>
          )}

          <div className="header-dropdown">
            <MenuAdditionally
              setNeedToOpenShift={this.props.setNeedToOpenShift}
              setOpeningShiftCallback={this.props.setOpeningShiftCallback}
            />
            {isFiscal && isAuthInFiscal ? (
              <MenuOperations
                setTypeOperation={this.props.setTypeOperation}
                setNeedToOpenShift={this.props.setNeedToOpenShift}
                setShowPinCodeModal={this.props.setShowPinCodeModal}
                setOpeningShiftCallback={this.props.setOpeningShiftCallback}
              />
            ) : (
              ''
            )}
            <Encashment />
          </div>
          <StatusBlock />
          <Notification/>
        </div>
        <div className="header-search-tablet">
          <SearchService />
        </div>
      </div>
    );
  }
}
HeaderBlock.defaultProps = {
  openingShiftCallback: null
};

HeaderBlock.propTypes = {
  servicesByTypes: PropTypes.array.isRequired,
  commissionProfiles: PropTypes.array.isRequired,
  serviceList: PropTypes.array.isRequired,
  balances: PropTypes.object.isRequired,
  fiscal: PropTypes.object.isRequired,
  kkmInfo: PropTypes.object.isRequired,
  getServicesList: PropTypes.func.isRequired,
  getCommissionProfiles: PropTypes.func.isRequired,
  closeShift: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  createInvoiceForKkm: PropTypes.func.isRequired,
  setShowLicenseModal: PropTypes.func.isRequired,
  isFiscal: PropTypes.bool.isRequired,
  hasCashOut: PropTypes.bool.isRequired,
  hasMyPost: PropTypes.bool.isRequired,
  isAuthInFiscal: PropTypes.bool.isRequired,
  needToOpenShift: PropTypes.bool.isRequired,
  showPinCodeModal: PropTypes.bool.isRequired,
  showConfirmCloseShift: PropTypes.bool.isRequired,
  openingShiftCallback: PropTypes.func,
  typeOperation: PropTypes.number.isRequired,
  setOpeningShiftCallback: PropTypes.func.isRequired,
  setShowPinCodeModal: PropTypes.func.isRequired,
  setNeedToOpenShift: PropTypes.func.isRequired,
  setTypeOperation: PropTypes.func.isRequired,
  setShowCashOutModal: PropTypes.func.isRequired,
  setShowMyPostPaymentModal: PropTypes.func.isRequired,
  selectCashOutService: PropTypes.func.isRequired,
  selectMyPostService: PropTypes.func.isRequired,
  setShowConfirmCloseShift: PropTypes.func.isRequired,
  goToMainPage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    hasCashOut: state.services.hasCashOut,
    hasMyPost: state.services.hasMyPost,
    serviceList: state.services.serviceList,
    servicesByTypes: state.services.servicesByTypes,
    isServiceListRequested: state.services.isServiceListRequested,
    isServiceListRequestedWithError: state.services.isServiceListRequestedWithError,
    balances: state.kassa.balances,
    fiscal: state.fiscal,
    showPinCodeModal: state.fiscal.showPinCodeModal,
    showConfirmCloseShift: state.fiscal.showConfirmCloseShift,
    needToOpenShift: state.fiscal.needToOpenShift,
    openingShiftCallback: state.fiscal.openingShiftCallback,
    typeOperation: state.fiscal.typeOperation,
    kkmInfo: state.fiscal.kkmInfo,
    isFiscal: state.fiscal.isFiscal,
    isAuthInFiscal: state.fiscal.isAuthInFiscal,
    commissionProfiles: state.commission.commissionProfiles,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      goToMainPage,
      getServicesList,
      getCommissionProfiles,
      setShowLicenseModal,
      createInvoiceForKkm,
      setFiscalScreen,
      closeShift,
      showError,
      setShowPinCodeModal,
      setShowConfirmCloseShift,
      setOpeningShiftCallback,
      setNeedToOpenShift,
      setTypeOperation,
      setShowCashOutModal,
      setShowMyPostPaymentModal,
      selectCashOutService,
      selectMyPostService,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBlock);
